import { set } from 'lodash'
// import axios from 'axios'


export default {
    namespaced: true,
    state: {
        list: [],
        arbitrageData: null,
        nothing: false,
        loading: false
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setNothingToShow(state, nothing) {
            state.nothing = nothing
        },
        addArray(state, array) {
            state.list = [...state.list, ...array]
        }
    },
    actions: {
        getModal({ commit, state, rootGetters, dispatch }, { year, type, page, limit }) {
            commit('setLoading', true)
            commit('setNothingToShow', false)

            dispatch('v3/API', {
                method: 'arbitrations',
                data: {
                    action: 'info',
                    ogrn: rootGetters['card/ogrn'],
                    year,
                    type,
                    page,
                    limit

                }
            }, { root: true }).then(e => {
                if(e?.data?.data) {
                    if(state.list.length && e?.data?.data?.list?.length) {
                        commit('addArray', e?.data?.data?.list)
                    } else if(!state.list.length && e?.data?.data?.list?.length) {
                        commit('setStateObject', { name: 'list', value: e?.data?.data?.list })
                    } else {
                        commit('setNothingToShow', true)
                    }
                }
            }).finally(() => {
                commit('setLoading', false)
            })
        },
        getArbitrage({ dispatch, rootGetters, commit }) {
            dispatch('v3/API', {
                method: 'arbitrations',
                data: {
                    action: 'stat',
                    ogrn: rootGetters['card/ogrn']
                }
            }, { root: true }).then(e => {
                if(e?.data?.data?.cases_count > 0) {
                    commit('setStateObject', { name: 'arbitrageData', value: e.data.data })
                }
            })
        },
        clearList({ commit }) {
            commit('setStateObject', { name: 'list', value: [] })
        }
    }
}

