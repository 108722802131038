var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    { staticClass: "vheader" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("headerLogo"),
          _vm._v(" "),
          _vm.showHeaderSearch
            ? _c("div", { staticClass: "header-menu-item__search desktop" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.dadataSearchQuery,
                      expression: "dadataSearchQuery",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "header-menu-item__search-input",
                  attrs: {
                    type: "text",
                    placeholder: "ИНН, ОГРН, название или ФИО",
                    autocomplete: "off",
                    id: "header-menu-item__search-input",
                  },
                  domProps: { value: _vm.dadataSearchQuery },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.dadataSearchQuery = $event.target.value.trim()
                      },
                      _vm.headerDadataSearch,
                    ],
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "esc", 27, $event.key, [
                          "Esc",
                          "Escape",
                        ])
                      ) {
                        return null
                      }
                      _vm.dadataSearchQuery = ""
                      _vm.dadataSeachCompanies = []
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _vm.dadataSearchQuery || _vm.dadataSeachCompanies.length
                  ? _c("div", {
                      attrs: {
                        id: "header-menu-item__search-suggestions-cross",
                      },
                      on: {
                        click: function ($event) {
                          _vm.dadataSearchQuery = ""
                          _vm.dadataSeachCompanies = []
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.dadataSeachCompanies.length
                  ? _c(
                      "div",
                      { attrs: { id: "header-menu-item__search-suggestions" } },
                      [
                        _c(
                          "ul",
                          {
                            attrs: {
                              id: "header-menu-item__search-suggestions-list",
                            },
                          },
                          _vm._l(_vm.dadataSeachCompanies, function (e, i) {
                            return _c(
                              "li",
                              {
                                key: i,
                                staticClass:
                                  "header-menu-item__search-suggestions-list-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.openCard(e.data.ogrn)
                                  },
                                },
                              },
                              [
                                e.data.state.status == "ACTIVE"
                                  ? _c("i", {
                                      staticClass: "uk-icon-check-circle",
                                      staticStyle: {
                                        margin: "0 5px 0 0",
                                        color: "#2ecc71",
                                      },
                                    })
                                  : e.data.state.status == "LIQUIDATING"
                                  ? _c("i", {
                                      staticClass: "uk-icon-minus-circle",
                                      staticStyle: {
                                        margin: "0 5px 0 0",
                                        color: "#e7843c",
                                      },
                                    })
                                  : e.data.state.status == "LIQUIDATED"
                                  ? _c("i", {
                                      staticClass: "uk-icon-times-circle",
                                      staticStyle: {
                                        margin: "0 5px 0 0",
                                        color: "#e74c3c",
                                      },
                                    })
                                  : e.data.state.status == "REORGANIZING"
                                  ? _c("i", {
                                      staticClass: "uk-icon-check-circle",
                                      staticStyle: {
                                        margin: "0 5px 0 0",
                                        color: "#2ecc71",
                                      },
                                    })
                                  : e.data.state.status == "BANKRUPT"
                                  ? _c("i", {
                                      staticClass: "uk-icon-check-circle",
                                      staticStyle: {
                                        margin: "0 5px 0 0",
                                        color: "#2ecc71",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticStyle: { "font-weight": "400" } },
                                  [_vm._v(_vm._s(e.value))]
                                ),
                                _vm._v(" "),
                                e.data.management
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "10px",
                                          "line-height": "normal",
                                          padding: "5px 0 0",
                                          overflow: "hidden",
                                          "white-space": "nowrap",
                                          "text-overflow": "ellipsis",
                                        },
                                      },
                                      [
                                        _c(
                                          "b",
                                          {
                                            staticClass:
                                              "header-menu-item__search-suggestions-list-item-post",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(e.data.management.post) +
                                                ": "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._m(1, true),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "padding-top": "5px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(e.data.management.name)
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("headerMenu"),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSignin
        ? _c("signin", { attrs: { close: _vm.closeSignInWindow } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "eol_container" }, [
      _c("p", { staticClass: "bold" }, [
        _vm._v("new.licexpert.ru скоро перестанет работать"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text_inner" }, [
        _vm._v(
          "Мы больше не поддерживаем эту версию проекта и планируем закрыть её 1 сентября 2023 года. "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text_inner" }, [
        _vm._v(
          "Взамен разрабатываем более современную и функциональную — v3.licexpert.ru. "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text_inner" }, [
        _vm._v(
          "Через некоторое время начнём перенаправлять отсюда на новую версию автоматически."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "b",
      {
        staticClass: "header-menu-item__search-suggestions-list-item-post-icon",
      },
      [_c("i", { staticClass: "lnr lnr-user" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }