<template>
       
        <header class="vheader">
            <div class="eol_container">
            <p class="bold">new.licexpert.ru скоро перестанет работать</p>
            <div class="text_inner">Мы больше не поддерживаем эту версию проекта и планируем закрыть её 1 сентября 2023 года. </div>
            <div class="text_inner">Взамен разрабатываем более современную и функциональную — v3.licexpert.ru. </div>
            <div class="text_inner">Через некоторое время начнём перенаправлять отсюда на новую версию автоматически.</div>
        </div>
        <div class="container">
            <headerLogo />
            <div v-if="showHeaderSearch" class="header-menu-item__search desktop">
                <input
                    type="text"
                    placeholder="ИНН, ОГРН, название или ФИО"
                    autocomplete="off"
                    v-model.trim="dadataSearchQuery"
                    @input="headerDadataSearch"
                    @keyup.esc="dadataSearchQuery = ''; dadataSeachCompanies = []"
                    id="header-menu-item__search-input"
                    class="header-menu-item__search-input"
                >
                <div v-if="dadataSearchQuery || dadataSeachCompanies.length" @click="dadataSearchQuery = ''; dadataSeachCompanies = []" id="header-menu-item__search-suggestions-cross"></div>
                <!-- <div v-if="dadataSearchQuery" id="header-menu-item__search-suggestions-lupa"></div> -->
                <div v-if="dadataSeachCompanies.length" id="header-menu-item__search-suggestions">
                    <ul id="header-menu-item__search-suggestions-list">
                        <li v-for="(e,i) in dadataSeachCompanies" :key="i" class="header-menu-item__search-suggestions-list-item" @click="openCard(e.data.ogrn)">
                            <i v-if="e.data.state.status == 'ACTIVE'" class="uk-icon-check-circle" style="margin: 0 5px 0 0; color: #2ecc71"></i>
                            <i v-else-if="e.data.state.status == 'LIQUIDATING'" class="uk-icon-minus-circle" style="margin: 0 5px 0 0; color: #e7843c"></i>
                            <i v-else-if="e.data.state.status == 'LIQUIDATED'" class="uk-icon-times-circle" style="margin: 0 5px 0 0; color: #e74c3c"></i>
                            <i v-else-if="e.data.state.status == 'REORGANIZING'" class="uk-icon-check-circle" style="margin: 0 5px 0 0; color: #2ecc71"></i>
                            <i v-else-if="e.data.state.status == 'BANKRUPT'" class="uk-icon-check-circle" style="margin: 0 5px 0 0; color: #2ecc71"></i>
                            <span style="font-weight: 400">{{e.value}}</span>
                            <div v-if="e.data.management" style="font-size: 10px; line-height: normal; padding: 5px 0 0; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                                <b class="header-menu-item__search-suggestions-list-item-post">{{e.data.management.post}}: </b>
                                <b class="header-menu-item__search-suggestions-list-item-post-icon"><i class="lnr lnr-user"></i></b>
                                <span style="padding-top: 5px;">{{e.data.management.name}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <headerMenu />
        </div>
        <signin v-if="showSignin" :close="closeSignInWindow"></signin>
    </header>
    
</template>

<script>
import axios from 'axios'
import signin from '@/components/global/header/signin.vue'
import headerMenu from '@/components/global/header/headerMenu.vue'
import headerLogo from '@/components/global/header/headerLogo.vue'

export default {
    components: {
        signin,
        headerMenu,
        headerLogo
    },
    data() {
        return {
            dadataSearchQuery: '',
            dadataSeachCompanies: [],
            showHeaderSearch: false,
            dadataTimerId: null
        }
    },
    methods: {
        closeSignInWindow() {
            this.$store.commit('header/toggleSignin', false)
        },
        showHeaderSearchFn() {
            if(window.location.pathname.includes('/company/') || window.location.pathname.includes('/person/')) {
                this.showHeaderSearch = true
            } else if(this.showHeaderSearch == true) {
                this.showHeaderSearch = false
            }
        },
        openCard(ogrn) { window.open(`${window.location.origin}/company/${ogrn}`) },
        headerDadataSearch() {
            if(this.dadataTimerId) {
                clearInterval(this.dadataTimerId)
            }
            this.dadataTimerId = setTimeout(this.headerDadataRequest, 300)
        },
        headerDadataRequest() {
            if(this.dadataSearchQuery) {
                axios({
                    method: 'POST',
                    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
                    headers: {
                        Authorization: `Token ${process.env.DADATA_TOKEN}`
                    },
                    data: {
                        query: this.dadataSearchQuery
                    }
                }).then(e => {
                    if(e?.data?.suggestions?.length) {
                        this.dadataSeachCompanies = e.data.suggestions.filter(elmnt =>  (elmnt?.data?.inn?.[0] ? elmnt?.data?.inn?.[0] : 0) + (elmnt?.data?.inn?.[1] ? elmnt?.data?.inn?.[1] : 0) != '99' )
                    } else {
                        this.dadataSeachCompanies = []
                    }
                })
            } else {
                this.dadataSeachCompanies = []
            }
        }
    },
    mounted() {
        this.showHeaderSearchFn()
    },
    watch: {
        $route() { this.showHeaderSearchFn() }
    },
    computed: {
        showSignin() { return this.$store.state.header.showSignin },
        hasWorkingRouter() { return this.$route.matched.length }
    }
}
</script>

<style lang="sass" scoped>
@use 'styles/mobile.sass'
@import 'styles/global-variables.sass'

#header-menu-item__search-input::placeholder
    font-style: italic
    color: #5e5e5e

.header-menu-item__search
    position: relative
    width: 525px
    margin: 0 10px
    line-height: $header-height - $header-offset
    background: transparent
    +mobile.desktop
        width: 300px
    +mobile.tablet
        display: none

#header-menu-item__search-suggestions
    width: 100%
    text-align: left

#header-menu-item__search-input
    width: 100%
    border-radius: 8px
    padding-left: 8px
    border: 0
    height: 35px
    padding-right: 40px
    overflow: hidden
    text-overflow: ellipsis

#header-menu-item__search-suggestions-lupa:after
    content: "\e86f"

#header-menu-item__search-suggestions-lupa
    display: none
    font-family: 'Linearicons-Free'
    color: #333
    position: absolute
    right: 24px
    top: 2px
    font-size: 12px
    cursor: pointer

#header-menu-item__search-suggestions-cross:after
    content: "\e870"

#header-menu-item__search-suggestions-cross
    display: block
    font-family: 'Linearicons-Free'
    color: #333
    position: absolute
    right: 7px
    top: 1px
    font-size: 15px
    cursor: pointer

#header-menu-item__search-suggestions-list
    padding: 0
    background-color: #fff
    color: #333
    border-right: 1px solid #496c93
    border-left: 1px solid #496c93
    border-bottom: 1px solid #496c93
    border-bottom-right-radius: 10px
    border-bottom-left-radius: 10px
    li
        cursor: pointer
        text-overflow: ellipsis
        white-space: nowrap
        overflow: hidden
        padding: 5px 20px
        font-size: 12px
        border-bottom: 1px solid #d4d4d4
        line-height: normal
        &:hover
            background-color: rgba(72, 115, 203, 0.2)
        &:last-child
            border: 0
    .header-menu-item__search-suggestions-list-item-post
        @media screen and (max-width: 767px)
            display: none
    .header-menu-item__search-suggestions-list-item-post-icon
        display: none
        @media screen and (max-width: 767px)
            display: inline-block

.vheader
    width: 100%
    background-color: $header-background-color
    color: $header-color
    height: $header-height
    font-family: $montseratt
    vertical-align: top
    position: fixed
    .eol_container
        background-color: #ffb703
        padding: 10px
        width: 100%
        color: black
        white-space: nowrap
    .text_inner
        font-size:12px
    .bold
        font-weight: 800
        font-size: 18px
    a
        color: $header-color
        text-decoration: none
        border: 0
        &:hover
            color: $header-color
    .container
        top: 100px
        display: flex
        justify-content: space-between
        white-space: nowrap
    &-menu,
    &-menu-item
        display: inline-block
        vertical-align: top




</style>