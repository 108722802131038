import axios from 'axios'
import { set } from 'lodash'
import { getQueryStringValue } from '@/helpers.js'
import { cookieLib } from '../helpers'

const userModule = {
    namespaced: true,
    state: {
        groups: null,
        user_data: null,
        subscribes: null,
        limits: null,
        auth: JSON.parse(window.localStorage.getItem('auth')),
        apiUser: window.localStorage.getItem('apiUser'),
        userName: window.localStorage.getItem('userName'),
        apiSession: cookieLib.getCookie('user_sid') || getQueryStringValue('sid')
    },
    mutations: {
        setStateObject(state, { name, value }) {
            set(state, name, value)
        }
    },
    actions: {

        authAPI(_, { data, method }) {
            return axios({
                baseURL: `${process.env.API_AUTH}/v3`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { data, method }
            }).then(resp => {
                if(resp.status === 200) {
                    return resp
                } else {
                    return null
                }
            }).catch(err => {
                console.log(err.message)
            })
        },
        signout({ dispatch, state }) {
            const { apiSession } = state
            return dispatch('authAPI', {
                data: { sid: apiSession },
                method: 'logout'
            }).then(e => {
                if(e.data?.code === 0) {
                    window.localStorage.removeItem('apiSession')
                    cookieLib.eraseCookie('user_sid')
                    window.localStorage.setItem('auth', 'false')
                    window.open(window.location.origin, '_self')
                }
            })
        },
        status({ dispatch, state }) {
            const { apiSession } = state
            return dispatch('authAPI', {
                data: { sid: apiSession },
                method: 'status'
            }).then(e => {
                if(e.data?.data) {
                    window.localStorage.setItem('auth', 'true')
                    window.localStorage.setItem('apiSession', apiSession)
                } else {
                    window.localStorage.setItem('auth', 'false')
                    window.localStorage.removeItem('apiSession')
                }
            })
        },
        login({ commit, dispatch }, { user_name, user_pass, product_code }) {
            return dispatch('authAPI', {
                method: 'login',
                data: {
                    user_name,
                    user_pass,
                    product_code
                }
            }).then(e => {
                if(e.data && e.data.data && e.data.data.SID) {
                    commit('setStateObject', { name: 'apiSession', value: e.data.data.SID  })
                    window.localStorage.setItem('apiSession', e.data.data.SID)
                    cookieLib.setCookie('user_sid', e.data.data.SID, 30)
                    window.localStorage.setItem('auth', 'true')
                    window.location.reload()
                } else {
                    console.log(e.data.code)
                    if(e?.data?.code) {
                        let message
                        switch(e.data.code) {
                            case 0:
                                window.location.reload()
                                break
                            case 6:
                                message = 'Заняты все доступные сеансы'
                                break
                            case 3:
                            case 4:
                                message = 'Неверный логин или пароль'
                                break
                            case 10:
                                message = 'Введенные данные от другого продукта'
                                break
                            case 15:
                                message = 'Отсутсвует действующая подписка. Для продления доступа вы можете бесплатно позвонить на номер 8(800)500-40-18 или написать письмо на email: <a href="mailto:inf@1clicom.ru">inf@1clicom.ru</a>'
                                break
                            case 14:
                                message = 'Не указаны логин и/или пароль'
                                break
                            case 41:
                                message = 'Во время авторизации произошла ошибка. При повторном появлении ошибки, обратитесь в службу технической поддержки.'
                                break
                            default:
                                message = 'Неизвестная ошибка'
                                break
                        }

                        $('#modal-signin-login').addClass('uk-form-danger')
                        $('#modal-signin-password').addClass('uk-form-danger')

                        dispatch('showErrorPopUp', message, { root: true })
                    }
                }
            })
        },
        getUserInfo({ dispatch, commit, state }) {
            const { apiSession } = state

            return dispatch('authAPI', {
                data: {
                    sid: apiSession,
                    get_subscr: true
                },
                method: 'user'
            }).then(e => {
                if(e.data) {
                    if(e.data.data.groups) {
                        commit('setStateObject', { name: 'groups', value: e.data.data.groups })
                    }
                    if(e.data.data.subscribes) {
                        commit('setStateObject', { name: 'subscribes', value: e.data.data.subscribes })
                    }
                    if(e.data.data.limits) {
                        commit('setStateObject', { name: 'limits', value: e.data.data.limits })
                    }
                    commit('setStateObject', { name: 'user_data', value: {
                        user_id: e.data.data.user_id,
                        user_name: e.data.data.user_name,
                        user_login: e.data.data.user_login,
                        user_contact_name: e.data.data.user_contact_name,
                        user_bank_inn: e.data.data.user_bank_inn,
                        user_phone: e.data.data.user_phone,
                        user_email: e.data.data.user_email,
                        user_status: e.data.data.user_status,
                        monitoring_limit: e.data.data.monitoring_limit,
                        fl_online: e.data.data.fl_online
                    }})
                }
                return e.data.data
            }).catch(err => {
                console.log(err)
            })
        }
    }
}

export default userModule